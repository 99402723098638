html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  --pagePadding: 1.5rem;
  --sectionMargin: 2rem;
  --flexGap: 2rem;
  --cardPadding: 2rem;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.lightening {
  position: relative;
}

.lightening::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 1px;
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  background: linear-gradient(180deg, rgba(38, 153, 86, 0.48) 14.37%, #3FFF90 59.27%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

* {
  /* outline: 1px red solid; */
}

@media screen and (min-width:425px) {
  html {
    --pagePadding: 2rem;
  }
}

@media screen and (min-width: 768px) {
  html {
    --pagePadding: 2.6rem;
  }
}

@media screen and (min-width: 1024px) {
  html {
    --pagePadding: 3.6rem;
  }

  .lightening::before {
    height: auto;
  }
}

@media screen and (min-width: 1280px) {
  html {
    --pagePadding: 4.6rem
  }
}

@media screen and (min-width: 1440px) {
  html {
    --pagePadding: 6.875rem
  }
}